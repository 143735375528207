import React from 'react';

import Page from '../src/hocs/defaultPage';
import Signin from '../src/containers/Signin';

import redirect from '../src/helpers/redirect';
import { getCookie } from '../src/helpers/session';

import { NextPage } from 'next';

const Index: NextPage = Page(() => <Signin />);

Index.getInitialProps = async (context) => {
  const isLoggedIn = getCookie('id_token', context.req) ? true : false;
  if (isLoggedIn) {
    redirect(context, '/dashboard');
  }
};

export default Index;
