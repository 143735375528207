import React from 'react';

import { Grid } from '@l_ine/core';
import { IntlMessages, Layout } from '../../components';
import { Field, utils, Typography, Button, colorPalette } from '@l_ine/core';
import * as styled from './index.style';
const banner = 'static/svgs/loginBanner.svg';

import { connect } from 'react-redux';
import AuthActions from '../../redux/auth/actions';
import AppActions from '../../redux/app/actions';

import Router from 'next/router';

import { SignInProps } from './index.d';

import { useRouter } from 'next/router';
import { usePathname } from '@l_ine/hooks';

const Form = (props: any) => {
  const router = useRouter();
  const pathname = usePathname({ router });

  return (
    <>
      <Grid item xs={12}>
        <styled.WelcomeText>
          <Typography variant='titleLG'>
            {props.descricao || <IntlMessages id='signin.welcome.text' />}
          </Typography>
          <styled.Divider />
        </styled.WelcomeText>
      </Grid>
      <Grid item xs={12} style={{ padding: '16px' }}>
        <Field
          fieldSize={'lg'}
          label={<IntlMessages id='email' />}
          name='email'
          placeholder='Seu e-mail'
          required
          validate={utils.rules.composeValidators(utils.rules.required, utils.rules.email)}
          inputProps={{
            autocomplete: 'off',
            'data-testid': `${pathname}--field-seuEmail`,
          }}
        />
      </Grid>
      <Grid item xs={12} style={{ padding: '16px' }}>
        <Field
          fieldSize={'lg'}
          label={<IntlMessages id='password' />}
          placeholder='Sua senha'
          name='password'
          mode='password'
          inputProps={{
            autocomplete: 'off',
            'data-testid': `${pathname}--field-suaSenha`,
          }}
        />
      </Grid>
      <Grid item xs={12} style={{ padding: '16px' }}>
        <styled.RememberGrid>
          <Field
            type='checkbox'
            name='remember'
            label={<IntlMessages id='signin.remember' />}
            mode='checkbox'
            inputProps={{
              autocomplete: 'off',
              'data-testid': `${pathname}--checkbox-manterConectado`,
            }}
          />
          <a onClick={() => Router.push('/forgotpassword')}>
            <Typography
              variant='bodyXS'
              align='end'
              style={{
                cursor: 'pointer',
                color: colorPalette.neutral[100],
              }}>
              <IntlMessages id='signin.password.forgot' />
            </Typography>
          </a>
        </styled.RememberGrid>
      </Grid>
      <Grid item xs={12} style={{ padding: '16px' }}>
        <Button
          size='md'
          type='submit'
          // onClick={props.form.submit}
          variant='contained'
          bgColor='primary'
          fullWidth={true}
          data-testid={`${pathname}--button-entrar`}>
          <IntlMessages id='signin.button' />
        </Button>
      </Grid>
    </>
  );
};

const SignIn: React.FC<SignInProps> = props => {
  const [preEmail, setPreEmail] = React.useState<string | string[]>('');
  const [logo, setLogo] = React.useState<string | undefined>('');

  React.useEffect(() => {
    if (props?.company?.logo) {
      setLogo(props.company.logo);
    }
  }, [props.company]);

  React.useEffect(() => {
    if (Router && Router.query && Router.query.email) {
      setPreEmail(Router.query.email);
    }
  }, [Router]);

  const onSubmit = async (values: any) => {
    const aux = {
      ...values,
      codigo_cidade: props.company?.codigo_cidade,
      emp_codigo: props.company?.emp_codigo,
    };
    props.login(aux);
  };

  return (
    <Layout
      logo={logo}
      bannerSrc={banner}
      bannerAlt='Logo do CFC'
      form={<Form company={props.company} />}
      initialValues={{
        email: preEmail,
      }}
      onSubmit={onSubmit}
    />
  );
};

const { login } = AuthActions;
const { toggleSnackBar } = AppActions;

export default connect(
  (state: any) => ({
    company: state.Company.companyInfo,
  }),
  { login, toggleSnackBar }
)(SignIn);
